/**
 * = Modals
 */

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: $color-navient-dark-blue-10;

  &.show {
    opacity: 0.45;
  }
}

.modal {
  &.static-example {
    position: relative;
    display: block;
  }

  .modal-dialog {
    // margin: 100px auto;

    .modal-content {
      border: 0;
      border-radius: 0.5rem;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);

      .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem 1rem 24px;
        border-bottom: none;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;

        button {
          &.close {
            background-color: transparent;
            font-size: $font-size-base;
            margin: 0 0 0 auto;
            padding: 0.5rem;
            border: 0;
            border-radius: 4px;
            // border-top-right-radius: 8px;

            &:hover {
              background-color: $color-gray-2;
            }

            >span:not(.sr-only) {
              display: block;
              background-color: transparent;
              color: $color-gray-7;
              font-size: 1.25rem;
              line-height: 1.25rem;
              height: 1.25rem;
              width: 1.25rem;
              border-radius: 50%;
              transition: all 0.2s ease;
            }
          }
        }
      }

      .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 0 32px 32px;

        h2 {
          font-weight: 700;
          margin-bottom: 4px;
        }

        h3 {
          margin-bottom: 0;
        }

        p {
          color: $color-gray-7;
          margin-bottom: 0;
        }

        hr {
          background-color: $color-gray-4;
          margin: 35px 0;
          opacity: 1;
        }

        .form-details {
          .form-group {
            margin-bottom: 8px;

            &.row {
              margin-right: 0;
              margin-left: 0;
            }
          }

          .form-label {
            padding: 0;
          }
        }

        .form-add {
          .row {
            margin-bottom: 16px;
          }
        }
      }

      .form-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        .btn {
          margin-left: 8px;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 0;
  }
}

.modal-50w{
    width: 50%;
}

// Confirmation Modals
.modal-confirmation {
  .modal-dialog {
    width: 400px;

    @media (max-width: 575px) {
      width: auto;
    }

    .modal-content {
      .modal-body {
        padding: 32px;

        .form-header {
          display: grid;
          grid-template-columns: 24px auto;
          gap: 16px;
          margin-bottom: 8px;
        }

        form {
          margin-top: 8px;

          .form-footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;

            .btn {
              margin-left: 8px;

              &:first-of-type {
                margin-left: 0;
              }
            }
          }
        }

        .confirmation-body {
          margin-left: 40px;

          p {
            color: $color-msb-gray-6;
          }
        }

        .confirmation-footer {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;

          .btn {
            margin-left: 8px;

            &:first-of-type {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media print {
    .noPrint {
        display: none;
    }
}