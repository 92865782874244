// 
// MSB Portal - Constituent Website Configuration
//
.msb-portal {
  &__header {
    background-color: $white;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  }

  &__nav {
    .nav-item {
      font-weight: 700;
      text-transform: uppercase;

      .nav-link {
        &.active {
          color: $cms-text-link;

          &:hover,
          &:active {
            color: $cms-text-link-hover;
          }
        }
      }
    }
  }
}

.msb-portal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: $color-msb-gray-6;
    text-decoration: none;

    &:hover,
    &:active {
      color: $color-msb-gray-9;
    }
  }
}

