.adGroup{
    display:flex;
    background-color: white; 
}
.dot{
    height: 70px;
    width: 70px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
.userDetails{
    margin-left: 20px;
}