.approvalsWidget{
    background-color: white;
    margin-top: 24px;
    flex:50%;
    padding: 12px;
}

.myControlClassName{
    border: none !important; 
    padding: 0 !important;
    color: #0057B6 !important;
}
.myControlClassName:hover { 
    cursor: pointer !important;
  }
.Dropdown-arrow{
    border-style: none !important;
}