.login-hero {
    position: relative;

    .img-heading {
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;

        h1,
        h5 {
            color: $white;
        }

        h1 {
            font-size: 2.375rem;
            font-weight: 700;
            line-height: 2.875rem;
        }

        h5 {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
    }
}

.login-section {
   position: relative;
   top: -55px;

   @media (max-width: 575px) {
       top: 0;
   }
}

.login-card {
    width: 50%;
    box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

    @media (max-width: 575px) {
        width: 100%;
    }

    &.card {
        border: none;

        @media (max-width: 575px) {
            border-radius: 0;
        }

        .card-body {
            padding: 48px;

            @media (max-width: 575px) {
                padding: 24px;
            }

            h3 {
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.5rem;
            }

            span {
                display: block;
                color: $color-gray-7;
                margin-bottom: 24px;
            }

            .row {
                margin-bottom: 16px;
            }
        }

        .card-footer {
            background-color: $color-gray-2;
            text-align: center;
            border-top: none;
            padding: 12px;

            p {
                color: $color-msb-gray-6;
                margin-bottom: 0;
            }
        }
    }

    .login-account {
        padding-right: 48px;
        border-right: 1px solid $color-gray-4;

        @media (max-width: 991px) {
            border-right: none;
            margin-bottom: 48px;
            padding-right: 12px;
        }

        .forgot-password {
            color: $cms-branding;
            text-align: center;
        }       
    }

    .login-noaccount {
        padding-left: 48px;

        @media (max-width: 991px) {
            padding-left: 12px;
        }
    }
}

.login-desc {
    margin: 48px 0;
    width: 50%;

    @media (max-width: 575px) {
        padding: 0 24px;
        width: 100%;
    }

    h3 {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
    }

    span {
        color: $color-gray-7;
    }
}

.login-payment-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
    padding: 0 24px;
}

.login-disclaimer {
    text-align: center;
    padding: 0 24px;
}