.card {
  position: relative;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  margin-bottom: 30px;
  
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    background: transparent;
    border-bottom: 1px solid $color-gray-5;
    border-radius: 0.25rem 0.25rem 0 0;
    height: 64px;

    h2 {
      margin: 0;
    }
  }

  .card-body {
    .table-search {
      label {
        white-space: nowrap;
      }

      .btn-toolbar {
        .btn-primary {
          margin-left: 8px;
        }
      }

      @media (max-width: 1199px) {
        .form-group {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1.5rem;

          .form-label {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}