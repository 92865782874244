:root {
--primary-blue: #0057B6;
--secondary-blue: #3379c5;
--primary-purple: #470A68;
--primary-gray: #414141;
--secondary-gray: #8C8C8C;
--light-bg-gray: #FAFAFA;
}

.msb-portal-status {
  background-color: $white;

  .h3,
  h3 {
    font-size: 1.75rem;
  }

  p {
    color: var(--secondary-gray);
    margin-bottom: 0;
  }

  .dark-gray {
    color: var(--primary-gray);
  }

  .gray-2 {
    background-color: var(--light-bg-gray);
  }

  .gray-7 {
    color: var(--secondary-gray);
  }

  .card {
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: none;
    margin-bottom: 0;

    .card-header {
      height: auto;

      .text-success {
        color: $color-polar-green-green-6 !important;
      }

      .text-warning {
        color: $color-calendula-gold-gold-6 !important;
      }

      .text-danger {
        color: $color-dust-red-red-6 !important;
      }
    }
  }

  .status-day {
    margin-top: 35px;
  }

  .status-day:nth-child(2) {
    margin-top: 20px;
  }

  .no-incident {
    color: var(--secondary-gray);
  }

  .date {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 3px;
    margin-bottom: 10px;
  }

  .incident-container {
    margin-bottom: 1.5rem;
  }

  .incident-title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.8125rem;
  }

  .update {
    margin: 0.5rem 0;
  }

  .update:last-of-type {
    margin-bottom: 0;
  }

  .update small {
    color: var(--secondary-gray);
  }

  [data-tooltip]:before,
  [data-tooltip]:after {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    transition: 0.2s ease-in-out 0.2s;
  }

  [data-tooltip]:before {
    content: "";
    height: 0;
    width: 0;
    bottom: calc(100% - 9px);
    border-style: solid;
    border-width: 10px;
    border-color: #414141 transparent transparent transparent;
  }

  [data-tooltip]:after {
    content: attr(data-tooltip);
    white-space: nowrap;
    background: #414141;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    box-sizing: border-box;
    bottom: calc(100% + 11px);
    color: #fff;
    border-radius: 4px;
    z-index: 9;
  }

  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    opacity: 1;
    visibility: visible;
    transition: 0.2s ease-in-out 0.2s;
  }

  .block-chart {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 9px 0 4px;
    width: 100%;
  }

  .bar {
    float: left;
    position: relative;
    height: 100%;
    width: 6px;
    cursor: pointer;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  /* .bar:first-child {
  border-left: 0;
  }

  .bar:last-child {
  border-right: 0;
  } */

  [data-status=up] {
    background: #52C41A;
  }

  [data-status=warning] {
    background: #FAAD14;
  }

  [data-status=down] {
    background: #F5222D;
  }

}