.registration-container{
    width: 540px;
    border-radius: 4px;
    background-color: white;
}

.registration-header {
    margin: 0 -32px;
    padding: 20px 32px;
    border-bottom: 1px solid #D9D9D9;
}

.registration-form {
    padding: 48px 0;

    h3 {
        font-weight: bold;
        margin-bottom: 4px;
    }
}

.password-strength-hint {
    background-color: #FAFAFA;
    padding: 8px;

    h6 {
        border-bottom: 5px solid #F5F5F5;
        padding-bottom: 5px;
        margin: 5px 0 20px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: #8C8C8C;
        font-size: 14px;
        line-height: 22px;

        li {
            margin-bottom: 5px;
            span {
                margin-left: 5px;
            }
        }
    }
}

.green-border {
    border-color: #52C41A !important;
}

.pwd-success {
    color: #414141 !important;
}

.action-btn {
    margin: 5px auto;
    text-align: center;

    .btn-link {
        color: #52C41A;
    }
}

.registration-acknowledge{
    width: 540px;
    margin: auto;
    text-align: center;
    font-family: 'Lato';
    padding-block: 20px;
}

.success-login {
    padding: 20px 32px;
    text-align: center;
}