.alert-heading {
    font-weight: $font-weight-bold;
}

.alert-icon {
    margin-bottom: .5rem;

    span {
        font-size: $alert-icon-font-size;
    }
}

.alert {
    color: $color-msb-gray-6;
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 24px;
    border: 0.0625rem solid transparent;
    border-radius: 0.25rem;

    h2 {
        margin-bottom: 0;
    }

    svg {
        font-size: 24px;
    }

    span {
        margin-top: 4px;
    }

    .alert-container {
        display: grid;
        grid-template-columns: 24px auto;
        column-gap: 16px;
    }

    &.alert-info {
        background-color: $color-navient-dark-blue-1;
        border-color: $color-navient-dark-blue-3;

        svg {
            color: $color-navient-dark-blue-6;
        }
    }

    &.alert-success {
        background-color: $color-polar-green-green-1;
        border-color: $color-polar-green-green-3;

        svg {
            color: $color-polar-green-green-6;
        }
    }

    &.alert-danger {
        background-color: $color-dust-red-red-1;
        border-color: $color-dust-red-red-3;

        svg {
            color: $color-dust-red-red-6;
        }
    }
}
