.marketing {
  background-color: $white;

  .gray-2 {
    background-color: $color-gray-2;
  }

  .nav {
    .nav-link {
      color: $color-msb-gray-6;
      font-weight: 700;
      transition: color 0.2s ease;

      &:hover,
      &:active {
        color: $color-navient-dark-blue-6;
      }
    }
  }
  
  .display-1 {
    font-size: 5rem;
    line-height: 5.375rem;
  }
  
  // .display-2 {
  //   font-size: 2.875rem;
  //   line-height: 3.375rem;
  // }

  .lead {
    color: #5C5C5C;
    line-height: 1.5;
  }

  .text-muted {
    color: $color-msb-gray-4 !important;
  }

  p {
    // font-size: 1rem;
    line-height: 1.5rem;
  }

  small a {
    color: $color-navient-dark-blue-6;
  }

  .cta-blue {
    background-color: $color-navient-dark-blue-6;
    padding: 48px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    box-shadow: 0px 0px 6px rgba(31, 41, 55, 0.05),
    0px 10px 15px rgba(31, 41, 55, 0.1);

    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }

    .cta-button {
      display: flex;
      justify-content: flex-end;

      button {
        box-shadow: 0px 0px 6px rgba(31, 41, 55, 0.05),
        0px 10px 15px rgba(31, 41, 55, 0.1);
      }

      @media (max-width: 575px) {
        justify-content: flex-start;
      }
    }

    h3, p {
      color: $white;
    }

    p {
      margin-bottom: 0;
    }
  }

  // Product features
  .product-feature-tabs {
    .nav-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .nav-link {
        display: grid;
        grid-template-columns: auto 56px;
        align-items: center;
        border: 1px solid $color-gray-5;
        border-radius: 4px;
        box-shadow: 0px 4px 6px rgba(31, 41, 55, 0.1),
        0px 2px 4px rgba(31, 41, 55, 0.06);
        padding: 1rem;

        &.active {
          background-color: $color-gray-3;
          box-shadow: none;
        }

        .tab-label {
          h5 {
            margin-bottom: 4px;
          }

          p {
            color: $color-msb-gray-6;
            margin-bottom: 0;
          }
        }

        .tab-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          height: 56px;
          width: 56px;
          border-radius: 50%;
          box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);

          svg {
            color: $color-msb-gray-6;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }

  .explore-solutions {
    .solution-cards {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 30px;

      @media (max-width: 767px) {
        grid-template-columns: repeat(2, auto);
      }

      @media (max-width: 575px) {
        grid-template-columns: repeat(1, auto);
      }

      .card {
        border: none;
        box-shadow: 0px 4px 6px rgba(31, 41, 55, 0.1),
        0px 2px 4px rgba(31, 41, 55, 0.06);

        .card-title {
          &.h5 {
            margin-bottom: 4px;
          }
        }

        .card-text {
          color: $color-msb-gray-6;

          a {
            color: $color-navient-dark-blue-6;
            text-decoration: none;
          }
        }

        .solution-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $color-navient-dark-blue-6;
          height: 56px;
          width: 56px;
          border-radius: 50%;
          margin: 0 auto 8px;

          svg {
            color: $white;
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }

  footer {
    a {
      color: $primary-blue;
      font-size: 0.875rem;
      text-decoration: none;

      &:hover {
        color: $secondary-blue;
      }
    }

    &.footer {
      p {
        font-size: 12px;
      }
    }
  }
}
