// @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// MSB Design System
@import "nexus/design_system";

// Change variables here
@import "nexus/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "nexus/vendor";

// mixins & functions
@import "nexus/mixins";
@import "nexus/functions";

// Utilities
@import "nexus/reboot";
@import "nexus/utilities";

// Layout
@import "nexus/layout";

// Components
@import "nexus/components";

// Marketing site
@import "nexus/marketing_site/marketing-site";

// MSB Portal
@import "nexus/msb-portal";

// Custom styles here
body {
  background-color: $color-gray-4;
  color: $color-msb-gray-6;
  font-size: 0.875rem;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}