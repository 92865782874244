@mixin input-placeholder {
    &.placeholder {
        @content;
        opacity: 1;
        transition: opacity 100ms ease-in-out;

    }

    &:-moz-placeholder {
        @content;
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }

    &::-moz-placeholder {
        @content;
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }

    &:-ms-input-placeholder {
        @content;
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }

    &::-webkit-input-placeholder {
        @content;
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }

    &::placeholder {
        @content;
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }
}

/* Inputs */
input::-ms-clear,
input::-ms-reveal {
    display: none;
}

/* Change Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:hover {
    border-color: $color-gray-5;
    -webkit-text-fill-color: $color-msb-gray-6;
    box-shadow: 0 0 0 1000px $white inset;
    
    &:disabled,
    &[readonly] {
        -webkit-text-fill-color: $color-msb-gray-3 !important;
        box-shadow: 0 0 0 1000px $color-gray-3 inset;
    }
}

label {
    font-weight: 400;
}

form .row {
    margin-bottom: 16px;
}

.form-header {
    margin-bottom: 24px;

    h2 {
        font-weight: $font-weight-bold;
        margin-bottom: 4px;
    }

    h3 {
        margin-bottom: 0;
    }

    p {
        color: $color-gray-7;
        margin-bottom: 0;
    }
}

.form-footer {
    margin-top: 48px;
}

.form-group {
    label {
        display: flex;
        align-items: center;
        font-weight: 400;

        &.form-check-label {
            font-weight: 400;
        }
    }
    
    .input-group {
        .input-group-append {
            .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-left: 1px solid $white;
            }
        }
        
    .input-group-text {
        color: $color-gray-7;
        background-color: $white;
        border: 1px solid $color-gray-5;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        min-height: 100%;
        min-width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type=number] {
        border-left: 1px solid $white;

        &:focus,
        &:active {
            border-left: 1px solid $color-navient-dark-blue-6;
        }
    }

    .form-control:not(:last-child) {
        border-right: 1px solid $color-gray-5;
        // transition: border-right 0.15s ease-in-out;

        // &:valid {
        //   border-right: 1px solid $color-polar-green-green-6;
        //   border-left: 1px solid $color-polar-green-green-6;
        // }

        // &:invalid {
        //   border-right: 1px solid $color-dust-red-red-6;
        //   border-left: 1px solid $color-dust-red-red-6;
        // }
    }

    // input[type=password] {
    //   border-right: 1px solid transparent !important;
    // }
    }

    .helper-text {
        display: block;
        color: $color-gray-7;
        margin-top: 2px;
    }

    :focus::-webkit-input-placeholder {
        opacity: 0.5;
    }

    :focus:-ms-input-placeholder {
        opacity: 0.5;
    }

    :focus::-moz-placeholder {
        opacity: 0.5;
    }

    :focus:-moz-placeholder {
        opacity: 0.5;
    }
}

input,
select {
    height: 41px;
}

input[type=radio] {
    height: auto;
}

.form-control,
.input-group .form-control {
    color: $color-msb-gray-6;
    font-size: 0.875rem;
    padding: 0.563rem 0.75rem;
    box-shadow: none;
    // appearance: auto;
    border-radius: 0.25rem;
    border: 1px solid $color-gray-5;

    @include input-placeholder {
        color: $color-gray-7;
    }

    &:focus,
    &:active {
        color: $color-msb-gray-6;
        outline: 0;
        box-shadow: none;
        border-color: $color-navient-dark-blue-6 !important;
    }

    &:disabled,
    &[readonly] {
        color: $color-msb-gray-3;
        background-color: $color-gray-3;
        cursor: not-allowed;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-color: #d9d9d9;

        &:focus,
        &:active {
            border-color: $color-gray-5;
        }
    }

    @include media-breakpoint-up(lg) {
        &.form-control-lg {
            padding: $inpu-lg-padding-y $input-padding-x;
        }
    }
}

// Date and Time picker icons
input[type="date"i]::-webkit-calendar-picker-indicator {
    background-image: url(../../../assets/img/icons/ico-calendar-regular.svg);
    background-size: 13px;
    padding: 0;
}

input[type="time"i]::-webkit-calendar-picker-indicator {
    background-image: url(../../../assets/img/icons/ico-clock-regular.svg);
    background-size: 13px;
    padding: 0;
}

// Custom Select menu arrow
select.form-control {
    background-color: $white;
    background-image: url(../../../assets/img/icons/ico-chevron-down-regular.svg);
    background-repeat: no-repeat;

    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    background-position: 96.5% !important;
    /* !important used for overriding all other customisations */
    background-position: calc(100% - 12px) !important;
    background-size: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:disabled {
        background-image: url(../../../assets/img/icons/ico-chevron-down-regular-disabled.svg);
    }
}

/*For IE*/
select::-ms-expand {
    display: none;
}

.form-check-input {
    background-color: $white;
    border: 1px solid $color-msb-gray-3;

    &.round-check {
        &[type="checkbox"] {
            @include border-radius($form-check-radio-border-radius);
        }
    }

    &[type="radio"] {
        background-image: none !important;

        &:checked {
            background-color: $color-navient-dark-blue-6;
            border: 1px solid $color-navient-dark-blue-6;
            box-shadow: inset 0px 0px 0px 3px $white;
        }
    }
}

input[type=checkbox][disabled], input[type=radio].disabled, input[type=radio][disabled] {
    cursor: not-allowed;
}

.search-bar {
    .form-control {
        width: 280px;
        transition: width .2s ease-in-out;

        @include media-breakpoint-down(sm) {
            width: 120px;

            &:focus {
                width: 150px;
            }
        }
    }
}

.input-group {
    border-radius: 0.25rem;

    .form-control {
        &:focus {
            border-color: $input-border-color;

            &+.input-group-text {
                border-color: $input-border-color;
                border-radius: 0;
            }
        }

        &.password {
            border-right: 1px solid transparent !important;
        }

        &:not(:last-child) {
            // border-left: 1px solid $input-border-color !important;
            padding-right: 0.75rem !important;
            // padding-left: 0.75rem !important;

            &:focus {
                border-right: 1px solid $color-navient-dark-blue-6 !important;
                border-left: 1px solid $color-navient-dark-blue-6 !important;
            }
        }
    }
}

// .input-group-text {
//   border-radius: 0;
// }

.focused {
    .form-control {
        border-color: $input-focus-border-color;
        background-color: $input-focus-bg;
    }
}

.form-switch {
    padding-left: $form-switch-padding-start;

    .form-check-input {
        height: 1.275em;
    }
}

.form-select {
    .form-select-lg {
        padding: $form-select-padding-y ($form-select-padding-x + $form-select-indicator-padding) $form-select-padding-y $form-select-padding-x;
    }
}

//Add File Alternative Style
.file-field input[type="file"] {
    max-width: 230px;
    position: absolute;
    cursor: pointer;
    filter: alpha(opacity=0);
    opacity: 0;
    padding-bottom: 30px;
}

.file-field span {
    cursor: pointer;
}

.datepicker-dropdown {
    z-index: 9999;
}

//Custom form validation
.customValidation {
    border-color: #f5222d !important;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(1.375em + 0.125rem) center;
    background-size: auto;
    padding-right: 0.75rem;
    padding: 0.563rem 0.75rem;
    box-shadow: none;
    border-radius: 0.25rem;
    border: 1px solid #d9d9d9;
    transition: none;
    display: block;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #ffffff;
    background-clip: padding-box;
    appearance: none;

    &:focus,
    &:focus {
        border-color: #f5222d !important;
        outline: none;
        box-shadow: none;
    }
}

// Table Search Form
form {
    &.table-search {
        .form-group {
            display: flex;
            align-items: center;

            label {
                margin: 0 8px 0 0;

                span {
                    color: $color-gray-7;
                }
            }
        }

        .btn-toolbar {
            justify-content: flex-end;
            gap: 8px;
        }
    }
}

// Validation
.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: $color-dust-red-red-6;
    // background-image: url(../../../assets/img/icons/ico-error.svg);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(1.375em + 0.125rem) center;
    background-size: auto;
    padding-right: calc(1.5em + 0.5rem);

    &:focus,
    &:focus {
        border-color: $color-navient-dark-blue-6;
        box-shadow: none;
    }
}

.invalid-feedback {
    color: $color-dust-red-red-6;
}

.form-custom-error{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: $color-dust-red-red-6;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: $color-polar-green-green-6;
    // background-image: url(../../../assets/img/icons/ico-success.svg);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(1.375em + 0.125rem) center;
    background-size: auto;
    padding-right: calc(1.5em + 0.5rem);

    &:focus,
    &:focus {
        border-color: $color-navient-dark-blue-6;
        box-shadow: none;
    }
}
// .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
//   margin-left: 0;
// }

.input-group .form-control:not(:first-child) {
    border-left: 1px solid $white !important;

    &:focus {
        border-left: 1px solid $color-navient-dark-blue-6 !important;
    }

    // &:valid {
    //   border-left: 1px solid $color-polar-green-green-6 !important;
    // }
}


// React Select menu
.react-select-container {
    .react-select {
        &__control {
            background-color: $white;
            color: $color-gray-7;
            border: 1px solid $color-gray-5;
            min-height: 41px;

            &:hover {
                border: 1px solid $color-gray-5;
            }

            &--is-focused {
                color: $color-msb-gray-6;
                outline: 0;
                box-shadow: none;
                border-color: $color-navient-dark-blue-6 !important;
            }
        }

        &__clear-indicator {
            display: none;
        }

        &__indicator-separator {
            display: none;
        }

        &__input {
            height: 22px;

            input {
                height: auto;
            }
        }

        &__dropdown-indicator {
            background-color: $white;
            background-image: url(../../../assets/img/icons/ico-chevron-down-regular.svg) !important;
            background-repeat: no-repeat !important;

            /* Some browsers will not display the caret when using calc, so we put the fallback first */
            background-position: 96.5% !important;
            /* !important used for overriding all other customisations */
            background-position: calc(100% - 12px) !important;
            background-size: 10px !important;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 24px;

            svg {
                display: none;
            }
        }

        &__value-container {
            padding: 5px 8px;
        }

        &__multi-value {
            background-color: $color-gray-3;
            border-radius: 2px;

            &__remove {
                cursor: pointer;

                &:hover {
                    background-color: $color-gray-7;
                    color: #fff;
                    border-radius: 0 2px 2px 0;
                }
            }
        }

        &__menu {
            border: none;
            border-radius: 4px;
            box-shadow: 0px 4px 6px rgba(31, 41, 55, 0.1),
                0px 2px 4px rgba(31, 41, 55, 0.06);
            z-index: 99999;
            position: absolute;
        }

        &__placeholder {
            transform: translateY(-51%);
        }
    }

    .css-b8ldur-Input {
        margin: 0 2px;
        padding: 0;
    }
}

.fa {
    // font-family: "Font Awesome 5 Pro";
    font-weight: 400;
}

hr {
    background-color: $color-gray-4;
    margin: 35px 0;
    opacity: 1;
}

// Checkboxes
.form-check-input:checked[type=checkbox] {
    background-position: 0;
}

.form-check-input:focus {
    border-color: $color-navient-dark-blue-6;
    outline: 0;
    box-shadow: none;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
    box-shadow: none;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: $color-polar-green-green-6;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: $color-msb-gray-6;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: $color-polar-green-green-6;
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    border-color: $color-dust-red-red-6;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: $color-msb-gray-6;
}

.form-notifications {
    .form-check {
        margin-bottom: 0;
        padding-left: 0;
    }
}

form {
    .row {
        margin-bottom: 0;

        .form-group {
            margin-bottom: 16px;
        }

        &:last-of-type {
            .form-group {
                margin-bottom: 0;
            }
        }

        // &:nth-last-child(-n+2) .form-group {
        //     margin-bottom: 0;
        // }
    }
}