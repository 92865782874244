.payment-container {
    display: flex;
    justify-content: space-between;
}

.default-tag {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    background: #fafafa;
    padding: 4px 8px;
    height: 20px;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    color: #414141;
    margin-left: 12px;
}