.spinner-border {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 1rem !important;
  height: 1rem !important;
  border: 1.5px solid $color-navient-dark-blue-6 !important;
  border-right-color: transparent !important;
}

.authorizing {
  .spinner-border {
    margin: 0;
    margin-right: 12px;
  }
}