@print {
  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}

@media print {
  // Remove URL from browser print
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  * {
    font-size: 10px;
    line-height: 16px;
  }

  body {
    background-color: $color-gray-1;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  // Geneal print layout
  p,
  h3,
  h4,
  h5,
  h6,
  span,
  strong {
    font-size: 10px;
    line-height: 16px;
  }

  h2 {
    font-size: 14px;
    line-height: 1;
  }

  hr {
    background-color: $color-gray-5;
    margin: 14px 0;
    opacity: 1;
    display: none;
  }

  // Component layout
  .card {
    box-shadow: none;

    .card-body {
      padding: 0;

      .transactionDetail {
        margin-bottom: 0;
      }
    }
  }

  // Receipt layout
  .container-receipt-details .receipt-body {
    font-size: 10px;
    line-height: 16px;
  }

  .container-receipt-details .receipt-body .receipt-detail,
  .container-receipt-details .order-body .receipt-detail {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 10px;
    line-height: 16px;
    width: 100%;

    span {
      text-align: right;
    }
  }

  .receipt-details {
    img.react-page-plugins-content-image {
      display: block;
      margin: 0 auto;
      height: auto;
      max-width: 300px;
    }
  }

  // Transaction details layout
  .transactionDetail {
    margin-bottom: 0;
  }
}