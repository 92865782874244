.container-payment-details {
  .list-group-item {
    border: none;
    border-bottom: 1px solid $color-gray-4;
    padding: 19px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
  
  // Transaction Summary Container (Right)
  .container-transaction-summary {
    h2 {
      font-weight: $font-weight-bold;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: $font-weight-bold;

      svg {
        position: relative;
        top: -5px;
      }
    }

    .list-group-item {
      padding-bottom: 27px !important;

      &:last-of-type {
        padding-top: 27px !important;
        padding-bottom: 0 !important;
      }

      h2 {
        margin-bottom: 24px;
      }

      p {
        display: none;
      }

      .errorDetail {
        color: $color-dust-red-red-6;
        margin-bottom: 16px;
      }

      .summaryDetail {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: baseline;
        margin-bottom: 16px;
        gap: 24px;

        span {
          display: flex;
          justify-content: end;
        }

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
          gap: 8px;

          span {
            justify-content: start;
          }
        }

        &:nth-of-type(2) {
          align-items: center;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        h3 {
          margin-bottom: 0;
        }

        .input-group-text {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      .actionBox {
        margin-top: 48px;

        .summaryButtons {
          display: flex;
          flex-direction: column;
          margin-top: 24px;
          margin-right: auto;
          margin-left: auto;

          .btn-primary {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
