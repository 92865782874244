.payment-details{
    padding: 20px 0;
    border-bottom: 1px solid $color-gray-3;

    &.row {
      margin-bottom: 0;
    }
}

.receiptAddress {
  display: block;
}

.receiptDisclaimer {
  width: 600px;
}

.transactionReceipt {
  margin-top: 20px;
}

.receiptDetails {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.receiptTitleContainer {
  text-align: center;
}

.receiptClient {
  margin-top: 4px;
}

.receiptBody {
  margin-top: 48px;
}

.receiptTitle {
  margin-top: 28px;
}

.receiptAmount {
  font-size: 20px;
}

.receiptDetail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px
}

.receiptButtons {
  margin-top: 54px;
}

.checkoutButton {
  display: block;
  width: 100%; 
  padding: 10px 5px; 
  margin: 10px;
}