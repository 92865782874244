/**
 * = List groups
 */

// Space list items
.list-group-space {
    .list-group-item {
        margin-bottom: 1.5rem;
        @include border-radius($list-group-border-radius);
    }
}


// List group components
.list-group-item{
    border:0;
    &.active {
        z-index: 2; // Place active items above their siblings for proper border styling
        color: $list-group-active-color;
        background-color: $list-group-active-bg;
        border-color: $list-group-active-border-color;
    }

    i{
        width: 1rem;
    }
}

.list-group-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: top;
    margin: -.1rem 1.2rem 0 -.2rem;
}
.list-group-content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;

    > p {
        color: $gray-500;
        line-height: 1.5;
        margin: .2rem 0 0;
    }
}
.list-group-heading {
    font-size: $font-size-base;
    color: $gray-800;

    > small {
        float: right;
        color: $gray-500;
        font-weight: 500;
    }
}


.list-group{
    &.simple-list{
        .list-group-item{
            background: transparent;
            border: none;
            padding: 0.375rem 0.125rem;
            i{
                vertical-align: middle;
                width: 35px;
                display: inline-block;
            }
        }
    }

}


//News list for widgets

.news-list{
	.row{
		> [class*='col']{
	  		padding: 0 $spacer/4;
		}
	}

	> li{
		&:not(:last-child){
		  margin-bottom: .75rem;
		}
	}

	img{
		min-height: 100%;
		object-fit: cover;
	}
}

// Neric specific style
.neric_details_row{
    display: grid;
    grid-template-columns: repeat(2, 1fr);  
    gap: 24px;
    align-items: center;
    margin-top: 8px;

    @media (max-width: 374px) {
      grid-template-columns: 1fr;
      gap: 0;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:first-of-type {
      margin-top: 0;
    }
    
    h3 {
      font-weight: $font-weight-bold;
      margin-bottom: 0;

      span {
        color: $color-gray-7;
        font-weight: $font-weight-normal;
      }
    }

    hr {
      margin: 27px 0 !important;
    }
  }

 .neric_container {
    display: flex;
    align-items: center;

    @media(max-width: 580px) {
        flex-direction: column;
        align-items: stretch;
    }
 }

 .neric_label {
    margin-right: 10px;
    align-items: center;
    display: inline-flex;
 }

 .neric_input{
    margin-right: 10px;
 }

 .neric_installment_subheading{
    font-weight: 700;
 }

 .verticalLine{
    width: 0;
    border-left: 1px solid #f0f0f0;
    height: 300px;
    margin: 0 10px;

    @media(max-width: 580px) {
        width: 100%;
        height: 1px;
        margin: 10px 0;
    }
 }

 //St charles styles

 .stCharles-accordion {
    .card {
      border: 1px solid $color-gray-5;
      border-radius: .25rem;
      box-shadow: none;
      overflow: hidden;
  
      .card-header {
        background: $color-gray-3 !important;
        height: auto;
        margin-bottom: -1px;
        padding: 12px 16px;
        border-radius: 0;
        cursor: pointer;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
  
        svg {
          margin-right: 16px;
        }
      }
  
      .card-body {
        // border-bottom: 1px solid $color-gray-5;
        padding: 16px;
  
        p {
          color: $color-gray-7;
          margin-bottom: 32px;
        }
  
        hr {
          background-color: $color-gray-4;
          margin: 19px 0;
          opacity: 1;
        }
        .list-group-item {
          padding: 0;
        }

        .agreement_input {
          height: 40px;
        }

      }
  
      .col-form-label {
        font-weight: $font-weight-normal;
        padding-top: 0;
        padding-bottom: 0;
      }
  
      &:not(:last-of-type) {
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
  
      &:not(:first-of-type) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .stCharles_container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
  }

  .stCharles_sideBySide{
    width: auto;
    margin-bottom: 20px;
    box-sizing: border-box;

    h3{
        font-weight: bold;
    }

    h2{
        border-bottom: 1px solid #f0f0f0;
    }

    p{
        font-weight: bold;
    }
    .stCharles_subHeading{
        display: flex;
        align-items: baseline;
    }
    .column_aligned{
        display: flex;
        flex-direction: column;
    }
    .item_value{
        margin-left: auto;
    }
    .centered_span{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f0f0f0;
        padding: 10px;
    }
    .centered_print{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
  }

  .stCharles_delinquent{
    margin-bottom: 10px;
    .centered_span{
        display: flex;
        padding: 10px;
        margin-bottom: 10px;
    }
  }

  .stCharles_cart {
      display: table;
      .box {
          display: table-row;
      }
      .box label,
      .box_span {
          display: table-cell !important;
          padding: 5px 10px !important;
      }
      .box_span {
          padding-left: 20px !important;
      }
  }

  .stCharles_table_details {
      display: flex;
      .container {
        border: 1px solid #f0f0f0;
        padding: 20px;
        flex: 1 1 0;
      }
      .radio_heading {
          display: flex;
          flex-direction: column;
          h3 {
              font-weight: bold;
              color: #035c8c
          }
          .radio_label {
              display: inline-flex;
              align-items: center;
              font-weight: bold;
          }
          .radio_label_zero {
            color: grey;
            display: inline-flex;
            align-items: center;
        }
          .radio_input {
              margin-right: 10px;
          }
      }
      .table_view {
        margin-left: 10px;
          flex: 4 1 0
      }

      @media(max-width: 1450px) {
        flex-direction: column;
        align-items: stretch;
        .container {
            border: none;            
          }
    }
  }

  //pike 
  .pikeDisplayResults{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 8px;
  }

  .mathewsDisplayResults{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 8px;
  }